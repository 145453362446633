.resultContainer {
    min-height: 200px;
    max-height: 350px;
  }
  .noResults {
    line-height: 1.5;
    font-style: italic;
    text-align: center;
  }
  .selectedRow {
    background-color: #DEEAFF;
  }
  .sortableColumn:hover {
    cursor: pointer;
    background-color: #E4E9F0;
  }
  .MuiTable-stickyHeader, .MuiTableCell-stickyHeader {
    z-index: 0 !important;
  }
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
   margin-bottom: 1px; 
  }
.markdownViewer {
    /* background-color: #FDFDFD;
    border: 1px solid #E4E9F0; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 16px;
  }
  h1 {
    font-size: 36px;
    margin-top: 20px;
  }
  h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  h3 {
    font-size: 24px;
    margin-top: 20px;
  }
  h4 {
    font-size: 18px;
    margin-top: 20px;
  }
  .markdownViewer > p > img {
    margin-left: 32px;
  }
  .markdownViewer > p > code {
    margin-left: 32px;
  }
  .markdownViewer > table {
    margin-left: 16px;
  }
  .markdownViewer > table,
  .markdownViewer > table > tbody > tr,
  .markdownViewer > table > tbody > tr > td,
  .markdownViewer > table > thead > th {
    padding: 8px;
    padding-right: 16px;
    border: 1px solid #9baab8;
  }
  .markdownViewer > table > thead {
    background-color: #e4e9f0;
  }
  
label {
    width: 20%;
  }
  #timeRange,
  #agentSelector,
  .inputField {
    display: flex;
    margin: 5px;
    align-items: center;
  }
  .searchForm {
    margin: 4px;
    width: 100%;
    float: left;
    border: 1px solid #e4e9f0;
  }
  
  .caluse {
    margin: 4px;
    width: 100%;
    float: left;
  }
  .collapsibleSearchForm {
    margin-bottom: 16px;
  }
  .applicableSearchScenario {
    background-color: white;
  }
  .accordionSummary {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: larger;
    font-weight: 500;
    color: black !important;
    min-height: 48px !important;
    max-height: 48px !important;
    background-color: #e4e9f0 !important;
  }
  input {
    margin: 4px 0px;
    border-color: 1px solid black;
    border-radius: 5px;
  }
  .valid-icon {
    margin-left: 8px;
    color: #3c8527;
  }
  .warning-icon {
    margin-left: 8px;
    color: #ea0b0b;
  }
  .conversationIdInput {
    width: 350px;
  }
  .MuiAccordionSummary-expandIcon {
    color: black !important;
  }
  .formInput {
    padding-left: 20px !important;
  }
  
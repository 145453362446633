* {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

html,
body {
  height: 100%;
}

body {
  color: #4C4C4C;
  font-family: "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  /* font-size: 0.9em; */
  line-height: 1.2;
  display: flex;
  flex-direction: column;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  background: #F6F6F6;
  width: 100%;
  height: 100%;
}

.MuiSvgIcon-root {
  color: #6c757d;
}

.MuiDialogTitle-root {
  background: #ff4f1f;
  color: white;
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 560px;
  min-width: 570px;
}

.alert-dailog .MuiButtonBase-root,
.MuiDialogContent-root .MuiButtonBase-root {
  background: #ff4f1f;
  color: white;
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.MuiButton-textPrimary:hover,
.MuiButton-root:hover {
  background-color: #ff4f1f !important;
  color: white !important;
}

.infoIcon {
  float: right;
  color: #ff4f1f;
}

.disclaimer {
  width: 74.5%;
  margin-right: right;
  text-align: end;
  margin: auto;
  margin-top: -16px;
  color: #B4BCCB;
  font-size: 12px;
  font-weight: bolder;
}

.disclaimer svg {
  margin-top: -3px;
  margin-right: -2px;
  font-size: 15px;
}

.MuiTableRow-head th {
  background-color: #6B7585;
  font-size: 14px;
  font-weight: 550;
  color: white;
}

.deleteMessage {
  margin: 15px 0px;
  font-size: 18px;
  color: #495057;
}

.confirm-title svg {
  margin-top: -5px;
  margin-right: 6px;
  color: white;
}

.guide-link {
  font-size: 12px;
  position: absolute;
  margin: -3px 9px;
}

.alert-dailog .MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 380px;
  min-width: 380px;
}

.MuiButton-textPrimary:hover {
  background-color: #ff4f1f !important;
  color: white !important;
}

.tobeinstall {
  margin-top: 5px;
  margin-bottom: 0.5rem;
  margin-left: 22px;
  text-align: initial;
}

.summary {
  margin: auto;
  display: inline-block;
}

.MuiDialog-container .MuiDialog-paperScrollPaper {
  display: block;
  /* overflow: hidden; */
}

.MuiTableRow-root .MuiTableCell-root {
  padding: 7px;
  line-height: 1;
  /* border-left: 0.25px solid #6c757d; */
}

.MuiPaper-root .MuiToolbar-regular {
  min-height: 35px;
}

.MuiPaper-root .MuiToolbar-gutters {
  padding-left: 10px;
}

.MTableToolbar-title-9 h6 {
  font-weight: 600;
}

.installed {
  line-height: 25px;
}

.helpIcon {
  right: 5px;
  top: 12px;
  position: absolute;
  cursor: pointer;
}
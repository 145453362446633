.MuiTabs-root {
    border-bottom: 1px solid #9BAAB8;
  }
  .MuiTab-root {
    border-radius: 8px 8px 0px 0px !important;
    border: 1px solid #9BAAB8 !important;
  }
  .headerTabs {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
    font-size: larger !important;
    font-weight: 500 !important;
  }
  .Mui-selected {
    background-color: #E4E9F0 !important;
  }
  .MuiTabs-indicator {
    background-color: #E4E9F0 !important;
  }
.MuiButton-contained {
    margin: 8px 8px !important;
    color: #FDFDFD !important;
    background-color: #2A60C8 !important;
  }
  .MuiButton-contained:hover {
    background-color: #75A8FF !important;
  }
  .MuiButton-contained:disabled {
    background-color: #9BAAB8 !important;
  }
  